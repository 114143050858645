import type {
  WAlbumView,
  WBrandCategoryView,
  WBrandDirectoryView,
  WCardSuiteSummaryView,
  WCategoryView,
  WShopSearchResultView,
} from '@zola/svc-web-api-ts-client';

import type { AnyAction } from 'redux';

import {
  receiveAlbums,
  receiveCategories,
  receiveDirectory,
  receivePaperBySearch,
  requestCategories,
  requestDirectory,
} from '~/actions/types/ShopActionTypes';

type AllCategories = {
  label: 'All Categories';
  key: null;
  id: null;
};

type ShopState = {
  busy: boolean;
  categories: (AllCategories | WBrandCategoryView)[];
  directory?: WBrandDirectoryView;
  categoryTree?: WCategoryView[];
  products?: WShopSearchResultView;
  paper?: WCardSuiteSummaryView[];
  albums?: WAlbumView[];
};
const initialState: ShopState = {
  busy: false,
  categories: [{ label: 'All Categories', key: null, id: null }],
};

const shopReducer = (state = initialState, action: AnyAction): ShopState => {
  if (requestDirectory.match(action) || requestCategories.match(action)) {
    return { ...state, busy: true };
  }

  if (receiveCategories.match(action)) {
    return {
      ...state,
      busy: false,
      categories: [{ label: 'All Categories', key: null, id: null }, ...action.payload],
    };
  }
  if (receiveDirectory.match(action)) {
    return { ...state, busy: false, directory: action.payload };
  }

  if (receivePaperBySearch.match(action)) {
    return { ...state, paper: action?.payload?.suites || [] };
  }
  if (receiveAlbums.match(action)) {
    return { ...state, albums: action?.payload || [] };
  }
  return state;
};

export default shopReducer;
