import { useMemo } from 'react';

import getEnvironment, { EnvironmentTypes } from '@zola-helpers/client/dist/es/util/environment';

import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { createTracker } from 'redux-segment';
import thunk from 'redux-thunk';

import reducers from '../reducers';

// Create Segment Tracker
const tracker = createTracker();

/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */
const composeEnhancers =
  getEnvironment() !== EnvironmentTypes.PRODUCTION && typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const enhancers = composeEnhancers(applyMiddleware(thunk, tracker));

export const finalReducers = combineReducers({
  ...reducers,
});

function initStore(preloadedState) {
  return createStore(finalReducers, preloadedState, enhancers);
}

let nextStore;
export const initializeStore = (preloadedState) => {
  // eslint-disable-next-line no-underscore-dangle
  let _store = nextStore ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && nextStore) {
    _store = initStore({
      ...nextStore.getState(),
      ...preloadedState,
    });
    // Reset the current store
    nextStore = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!nextStore) nextStore = _store;

  return _store;
};

export function useStore(initialState) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
