import { AnyAction } from '@reduxjs/toolkit';

import {
  receiveAddToRegistry,
  receiveSearch,
  selectCollection,
  requestAddToRegistry,
  requestSearch,
} from '~/actions/types/RegistryCollectionActionTypes';

const initialState = {
  addingProduct: false,
  requestingCollection: false,
  removingProduct: false,
  products: [],

  busy: false,
  initialized: false,
  defaultCollectionObjectId: undefined,
  defaultCollection: [],
  otherCollections: [],
  facets: [],
  selectedFacetValues: {},
  sort: '',
  isFlattenedView: false,
  selectedCollectionObject: null,
  collectionList: [],
};

const registryCollectionReducer = (state = initialState, action: AnyAction) => {
  if (requestAddToRegistry.match(action)) {
    return { ...state, addingProduct: true };
  }

  if (receiveAddToRegistry.match(action)) {
    return { ...state, addingProduct: false };
  }
  if (requestSearch.match(action)) {
    return { ...state, busy: true };
  }

  if (selectCollection.match(action)) {
    return { ...state, selectedCollectionObject: action.payload };
  }
  if (receiveSearch.match(action)) {
    return {
      ...state,
      busy: false,
      initialized: true,
      facets: action.payload.facets,
      defaultCollection: action.payload.default_collection,
      otherCollections: action.payload.other_collections,
      collectionList: action.payload.collection_list,
    };
  }

  return state;
};

export default registryCollectionReducer;
