import { GetServerSidePropsContext, NextPageContext } from 'next';

export const serverIsBaby = (context: GetServerSidePropsContext | NextPageContext): boolean => {
  let isBabyPage = false;
  const { req } = context;
  if (req) {
    const { host } = req.headers; // will give you localhost:3000
    if (host && host?.indexOf('baby') > -1) {
      isBabyPage = true;
    }
  }
  return isBabyPage;
};
