import { createAction } from '@reduxjs/toolkit';

import type { RegistryItemAddedEventData } from '~/pages/ProductDetailPage/pdpHelpers';

import type { AddToRegistryRequest } from '../RegistryCollectionActions';

const REQUEST_QUESTIONS = 'zola/onboard/REQUEST_QUESTIONS';
const RECEIVE_QUESTIONS = 'zola/onboard/RECEIVE_QUESTIONS';
const UPDATE_QUESTION_NUMBER = 'zola/onboard/UPDATE_QUESTION_NUMBER';
const REQUEST_SIGNUP = 'zola/onboard/REQUEST_SIGNUP';
const ONBOARDING_COMPLETED = 'zola/onboard/ONBOARDING_COMPLETED';
const UPDATE_WEDDING_DATE = 'zola/onboard/UPDATE_WEDDING_DATE';
const SET_ADD_TO_REGISTRY_PRODUCT_DATA = 'zola/onboard/SET_ADD_TO_REGISTRY_PRODUCT_DATA';
const SET_ADD_TO_REGISTRY_EVENT_DATA = 'zola/onboard/SET_ADD_TO_REGISTRY_EVENT_DATA';
const SET_ONBOARD_REDIRECT = 'zola/onboard/SET_ONBOARD_REDIRECT';
const CLEAR_ONBOARD_ADD_REGISTRY_DATA = 'zola/onboard/CLEAR_ONBOARD_ADD_REGISTRY_DATA';
const UPDATE_SUBMIT_DELAY_ACTIVE = 'zola/onboard/UPDATE_SUBMIT_DELAY_ACTIVE';

export const requestQuestions = createAction(REQUEST_QUESTIONS);

export const receiveQuestions = createAction<unknown>(RECEIVE_QUESTIONS);

export const requestSignup = createAction(REQUEST_SIGNUP);

export const onboardingCompleted = createAction<unknown>(ONBOARDING_COMPLETED);

export const updateQuestionNumber = createAction<unknown>(UPDATE_QUESTION_NUMBER);

export const updateWeddingDate = createAction<{
  date: unknown;
}>(UPDATE_WEDDING_DATE);

export const setOnboardAddToRegistryProductData = createAction<AddToRegistryRequest>(
  SET_ADD_TO_REGISTRY_PRODUCT_DATA
);

export const setOnboardAddToRegistryEventData = createAction<RegistryItemAddedEventData>(
  SET_ADD_TO_REGISTRY_EVENT_DATA
);

export const setOnboardRedirect = createAction<{ path: string; isRegistry: boolean }>(
  SET_ONBOARD_REDIRECT
);

export const clearOnboardProductData = createAction(CLEAR_ONBOARD_ADD_REGISTRY_DATA);

export const setSubmitDelayActive = createAction<boolean>(UPDATE_SUBMIT_DELAY_ACTIVE);
