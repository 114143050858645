/**
 * Created by zoechodosh on 2/17/17.
 */
import { Reducer } from 'redux';

import type { UserContext, UserActions } from '~/actions/UserActions.type';

import * as ActionTypes from '../actions/types/UserActionTypes';

export interface UserState {
  busy: boolean;
  userContext: UserContext | null;
  hasIdentified: boolean;
  hasGuestIdentified: boolean;
}

const initialState: UserState = {
  busy: false,
  userContext: null,
  hasIdentified: false,
  hasGuestIdentified: false,
};

const userReducer: Reducer<UserState, UserActions> = (state = initialState, action): UserState => {
  switch (action.type) {
    case ActionTypes.REQUEST_USER: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_USER: {
      return { ...state, busy: false, userContext: action.payload };
    }
    case ActionTypes.IDENTIFY_USER: {
      return { ...state, hasIdentified: true };
    }
    case ActionTypes.IDENTIFY_GUEST_USER: {
      return { ...state, hasGuestIdentified: true };
    }
    default:
      return state;
  }
};

export default userReducer;
