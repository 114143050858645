import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import { AnyAction } from '@reduxjs/toolkit';

import type { RegistryType } from '~/types/registry';

import {
  receivedCollectionInfoByRegistryId,
  receivedCollectionItemByCollectionId,
  receivedRegistryBySlug,
  receivedRegistryInfoByCollectionId,
  receiveRegistry,
  requestCollectionInfoByRegistryId,
  requestCollectionItemByCollectionId,
  requestRegistry,
  requestRegistryBySlug,
  requestRegistryInfoByCollectionId,
} from '../actions/types/RegistryActionTypes';

type RegistryState = {
  busy: boolean;
  collectionInfo: unknown | null;
  test: unknown | null;
  registry: RegistryType | null;
  collectionItem: WRegistryCollectionItemView | null | undefined;
  collectionItemInfo: RegistryType | null;
};

const initialState: RegistryState = {
  busy: false,
  collectionInfo: null,
  test: null,
  registry: null,
  collectionItem: null,
  collectionItemInfo: null,
};

export default function registryReducer(
  state: RegistryState = initialState,
  action: AnyAction
): RegistryState {
  if (requestRegistryBySlug.match(action)) {
    return { ...state, busy: true };
  }

  if (receivedRegistryBySlug.match(action)) {
    return { ...state, busy: false, registry: action.payload };
  }
  if (requestCollectionItemByCollectionId.match(action)) {
    return { ...state, busy: true, collectionItem: null };
  }
  if (receivedCollectionItemByCollectionId.match(action)) {
    return { ...state, busy: false, collectionItem: action.payload.data };
  }
  if (requestRegistryInfoByCollectionId.match(action)) {
    return { ...state, busy: true, collectionItemInfo: null };
  }
  if (receivedRegistryInfoByCollectionId.match(action)) {
    return { ...state, busy: false, collectionItemInfo: action.payload };
  }

  if (requestCollectionInfoByRegistryId.match(action)) {
    return { ...state, busy: true, collectionInfo: null };
  }
  if (receivedCollectionInfoByRegistryId.match(action)) {
    return { ...state, busy: false, collectionInfo: action.payload };
  }
  if (requestRegistry.match(action)) {
    return { ...state, busy: true };
  }

  if (receiveRegistry.match(action)) {
    return { ...state, busy: false, registry: action.payload };
  }
  return state;
}
