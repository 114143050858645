import accountPreferencesReducer from '@zola-helpers/client/dist/es/redux/accountPreferences/accountPreferencesReducer';
import toastsReducer from '@zola-helpers/client/dist/es/redux/toasts/toastsReducer';
import { userAwarenessReducer as userAwareness } from '@zola-helpers/client/dist/es/redux/userAwareness';

import { reducer as form } from 'redux-form';

import cart from './cart';
import checklist from './checklist';
import digitalGreetingCards from './digitalGreetingCards';
import externalFlow from './external';
import giftTracker from './giftTracker';
import { manageRegistryReducer } from './manageRegistry';
import modal from './modal';
import moduleGroup from './moduleGroup';
import nav from './nav';
import notifications from './notifications';
import onboard from './onboard';
import overview from './overview';
import personalization from './personalization';
import planner from './planner';
import product from './product';
import registry from './registry';
import registryBenefits from './registryBenefits';
import registryCollection from './registryCollection';
import review from './review';
import shop from './shop';
import thirdParty from './thirdParty';
import uploadcare from './uploadcare';
import user from './user';

export default {
  form,
  modal,
  user,
  cart,
  nav,
  toasts: toastsReducer,
  notifications,
  onboard,
  thirdParty,
  product,
  giftTracker,
  registryBenefits,
  registryCollection,
  overview,
  checklist,
  moduleGroup,
  registry,
  externalFlow,
  planner,
  shop,
  review,
  personalization,
  userAwareness,
  digitalGreetingCards,
  manageRegistry: manageRegistryReducer,
  uploadcare,
  accountPreferences: accountPreferencesReducer,
};
